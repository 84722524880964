<template>
    <div class="main-wrapper">
        <div class="main-wrapper-table">
            <div v-if="exam_content==='移动平台商品发布与管理'||exam_content==='网店商品发布与管理'">
                <el-table :data="goodsReleaseList"  class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px;">
                    <el-table-column prop="exam_content" label="考试内容" width="320px"></el-table-column>
                    <el-table-column label="学生提交答案" align="left" width="380px">
                        <template slot-scope="scope">
                            <div class="answer" v-if="scope.row">
                                <router-link class="a-link" :to="{path:'/teacher/goods/detail', query: {g_id:good_id}}" target="_blank">
                                    <img :src="scope.row.goods_images" alt="">
                                    <span class="text">{{scope.row.goods_title}}</span>
                                </router-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="search_num" v-if="exam_content==='网店商品发布与管理'" label="搜索人数" align="center"></el-table-column>
                    <el-table-column prop="click_rate" v-if="exam_content==='网店商品发布与管理'" label="点击率" align="center"></el-table-column>
                    <el-table-column prop="student_click_num" v-if="exam_content==='网店商品发布与管理'" label="学生点击量" align="center"></el-table-column>
                    <el-table-column prop="full_score_hits" v-if="exam_content==='网店商品发布与管理'" label="满分点击量" align="center"></el-table-column>
                    <el-table-column prop="stock" v-if="exam_content==='移动平台商品发布与管理'" label="总库存" align="center"></el-table-column>
                    <el-table-column prop="market_price" v-if="exam_content==='移动平台商品发布与管理'" label="销售价" align="center"></el-table-column>
                    <el-table-column :label="`得分(${total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row">{{scope.row.score}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="goodsShootList"  class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容" width="320"></el-table-column>
                    <el-table-column prop="module" label="考试模块"  width="380px"></el-table-column>
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDetailGoodsStu(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDetailGoods(scope.row)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${freight_info_total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row">{{scope.row.score}}</span>
<!--                            <span v-else>学生未提交</span>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else-if="exam_content==='网店订单管理设置'">
                <el-table :data="goodsOrderList" class="customTable goods-text-table exam-content" style="width: 100%; flex: 1; margin-top: 20px;">
                    <el-table-column  label="考试内容" width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.$index===0">{{exam_content}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_no"  label="订单编号" align="center" width="220"></el-table-column>
                    <el-table-column prop="scoring_operation"  label="得分操作" align="center" ></el-table-column>
                    <el-table-column label="学生提交答案" align="center" >
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row,1)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" align="center" >
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row,2)">查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  :label="`得分(${order_total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_answer">{{scope.row.stu_answer.score}}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--S 网店管理弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modifyOrderTitle"
                   custom-class="green-dialog02" :visible.sync="modifyOrderDialog" width="500px">
            <el-form :model="modifyOrderForm" ref="modifyOrderForm" label-position="right" label-width="100px">
                <el-form-item label="订单号:"  prop="order_no">
                    <div class="order-no-title">{{modifyOrderForm.order_no}}</div>
                </el-form-item>
                <div v-if="this.scoringOperation ==='出库'||this.scoringOperation ==='修改快递单号'">
                    <el-form-item label="快递" prop="delivery_mode">
                        <el-input v-model="modifyOrderForm.delivery_mode" autocomplete="off" style="width:340px" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="单号" prop="logistics_number">
                        <el-input v-model="modifyOrderForm.logistics_number"  autocomplete="off" style="width:340px" readonly></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="出库情况" prop="delivery_status" v-if="this.scoringOperation ==='退款审核（通过）'||this.scoringOperation ==='退款审核（驳回）'">
                    <el-input v-model="modifyOrderForm.delivery_status" autocomplete="off" style="width:340px" readonly></el-input>
                </el-form-item>
                <el-form-item label="驳回原因" prop="reasons_rejection" v-if="this.scoringOperation ==='退款审核（驳回）'">
                    <el-input v-model="modifyOrderForm.reasons_rejection"  autocomplete="off" style="width:340px" readonly></el-input>
                </el-form-item>
                <el-form-item label="审核原因" prop="audit_reasons" v-if="this.scoringOperation ==='换货审核（通过）'||this.scoringOperation ==='退货审核（通过）'||
                this.scoringOperation ==='换货审核（驳回）'||this.scoringOperation ==='退货审核（驳回）'">
                    <el-input v-model="modifyOrderForm.audit_reasons" autocomplete="off" style="width:340px" readonly></el-input>
                </el-form-item>
                <el-form-item label="驳回原因" prop="reasons_rejection" v-if="this.scoringOperation ==='换货审核（驳回）'||this.scoringOperation ==='退货审核（驳回）'">
                    <el-input v-model="modifyOrderForm.reasons_rejection"  autocomplete="off" style="width:340px" readonly></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="modifyOrderDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn()">确 定</el-button>
            </div>
        </el-dialog>
        <!--E 网店管理弹窗-->
    </div>
</template>

<script>
    import {shopManagementDetail} from '@/utils/apis'
    export default {
        name: "StoreManage",
        data() {
            return {
                examId:Number(this.$route.query.examId) || null,
                studentId:Number(this.$route.query.id) || null,
                type:'',//1是正确答案，2是学生答案
                total_score:0,//模块得分
                freight_info_total_score:0,
                //网店管理商品发布
                goodsReleaseList: [],
                //网店管理商品发布
                goodsShootList: [],
                // 网店订单管理
                goodsOrderList:[],
                exam_content:'',
                scoringOperation:'',//得分操作
                order_total_score:0,//订单模块得分
                //网店管理文字弹窗
                modifyOrderTitle:'',
                //修改快递单号弹窗
                modifyOrderDialog:false,
                modifyOrderForm:{
                    order_no:'',
                    delivery_mode:'',
                    logistics_number:'',
                    audit_reasons:'',
                    reasons_rejection:'',
                    delivery_status:'',
                },
                scoring_operation:'',
                good_id:'',//商品id
                moduleId: null,
            }
        },
        mounted() {
            this.getShopManagementDetail()
        },
        methods: {
            //获取网店详情
            getShopManagementDetail(){
                let params = {
                    exam_id:this.examId,
                    student_id:this.studentId,
                }
                shopManagementDetail(params).then((res) => {
                    if (res.data===15){
                        this.exam_content='网店商品发布与管理';
                        this.goodsReleaseList=[];
                        this.goodsShootList=[];
                    }else if(res.data===16){
                        this.exam_content='移动平台商品发布与管理';
                        this.goodsReleaseList=[];
                        this.goodsShootList=[];
                    }else if(res.data===18){
                        this.exam_content='网店订单管理设置';
                        this.goodsOrderList=[];
                    }
                    else {
                        if (res.data.goods_info){
                            this.goodsReleaseList.push(res.data.goods_info)
                            this.total_score= res.data.goods_info.total_score;
                            this.good_id= res.data.goods_info.id;
                        }
                        if (res.data.freight_info){
                            this.goodsShootList.push(res.data.freight_info)
                            this.freight_info_total_score= res.data.freight_info.total_score;
                        }
                        if (res.data.order_list){
                            this.goodsOrderList= res.data.order_list
                            this.order_total_score= res.data.total_score;
                        }
                        if ( res.data.freight_info){
                            this.exam_content = res.data.freight_info.exam_content
                        }else {
                            this.exam_content = res.data.exam_content
                        }
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //查看详情网店商品发布与管理-移动商品-正确答案
            viewDetailGoods() {
                if (this.$route.path === '/examCenter/trainingData/storeManage'){
                 this.$router.push({
                        path: '/examCenter/trainingData/freightDetail',
                        query:{
                            type:1,
                            examId:this.examId,
                            studentId:this.studentId,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/examCenter/examData/freightDetail',
                        query:{
                            type:1,
                            examId:this.examId,
                            studentId:this.studentId,
                        }
                    })
                }
            },
            //查看详情网店商品发布与管理-移动商品-学生提交答案
            viewDetailGoodsStu() {
                if (this.$route.path === '/examCenter/trainingData/storeManage'){
                    this.$router.push({
                        path: '/examCenter/trainingData/freightDetail',
                        query:{
                            type:2,
                            examId:this.examId,
                            studentId:this.studentId,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/examCenter/examData/freightDetail',
                        query:{
                            type:2,
                            examId:this.examId,
                            studentId:this.studentId,
                        }
                    })
                }
            },
            //网店订单 type=1 学生 2 答案
            viewOrderDetail(row,type) {
                this.modifyOrderDialog = true;
                this.scoringOperation = row.scoring_operation
                this.modifyOrderForm.order_no = row.order_no
                if (this.scoringOperation === '出库') {
                    this.modifyOrderTitle = '出库'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.delivery_mode = row.stu_answer.logistics;
                            this.modifyOrderForm.logistics_number = row.stu_answer.logistics_number;
                        }
                    }else {
                        this.modifyOrderForm.delivery_mode = row.answer.logistics;
                        this.modifyOrderForm.logistics_number = row.answer.logistics_number;
                    }
                }
                if (this.scoringOperation === '修改快递单号') {
                    this.modifyOrderTitle = '修改快递单号'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.delivery_mode = row.stu_answer.logistics;
                            this.modifyOrderForm.logistics_number = row.stu_answer.logistics_number;
                        }
                    }else {
                        this.modifyOrderForm.delivery_mode = row.answer.logistics;
                        this.modifyOrderForm.logistics_number = row.answer.logistics_number;
                    }
                }
                if (this.scoringOperation === '退款审核（通过）') {
                    this.modifyOrderTitle = '退款审核单审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.delivery_status = row.stu_answer.delivery_status;
                            this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
                        }
                    }else {
                        this.modifyOrderForm.delivery_status = row.answer.delivery_status;
                        this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
                    }
                }
                if (this.scoringOperation === '退款审核（驳回）') {
                    this.modifyOrderTitle = '退款审核单审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.delivery_status = row.stu_answer.delivery_status;
                            this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
                        }
                    }else {
                        this.modifyOrderForm.delivery_status = row.answer.delivery_status;
                        this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
                    }
                }
                if (this.scoringOperation === '退货审核（驳回）') {
                    this.modifyOrderTitle = '退货审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
                            this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
                        }
                    }else {
                        this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
                        this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
                    }
                }
                if (this.scoringOperation === '退货审核（通过）') {
                    this.modifyOrderTitle = '退货审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
                        }
                    }else {
                        this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
                    }
                }
                if (this.scoringOperation === '换货审核（通过）') {
                    this.modifyOrderTitle = '换货审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
                        }
                    }else {
                        this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
                    }
                }
                if (this.scoringOperation === '退货审核（驳回）') {
                    this.modifyOrderTitle = '换货审核'
                    if (type === 1){
                        if (row.stu_answer){
                            this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
                            this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
                        }
                    }else {
                        this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
                        this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
                    }
                }
            },
            // 修改快递单号确定事件
            addOrderBtn(){
                this.modifyOrderDialog = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 20px 20px 20px;
        .main-wrapper-table{
            display: flex;
            flex-direction: column;
            height: 100%;
            .main-wrapper-scrollbar{
                height: 100%;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
        }
        .goods-text-table {
            .answer {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                }
            }
            .a-link{
                height: 80px;
                display: flex;
                align-items: center;
                .text {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .dialog-footer{
            text-align: center;
            margin-top: 20px;
        }

        /*.order-main{*/
        /*    display: flex;*/
        /*    flex-direction: column;*/
        /*    height: 100%;*/
        /*}*/
    }
</style>